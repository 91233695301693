













import {Vue, Component} from "vue-property-decorator";
import {StoredControl} from "@/request/marketing/StoredControl";

@Component
export default class A extends Vue {
    modal: any = '';
    loading: boolean = false;
    height: number = document.body.clientHeight - 130;
    width: number = document.body.clientWidth - 260;
    isUseWalletPay: number = 0;
    created() {
        this.getUseWalletPay();
    }
    getUseWalletPay() {
        this.loading = true;
        StoredControl.getUseWalletPay().then((body: any) => {
            this.loading = false;
            if (body.code === 0) {
                this.isUseWalletPay = body.data;
            }
        })
    }
    setUseWalletPay() {
        this.loading = true;
        StoredControl.setUseWalletPay().then((body: any) => {
            this.loading = false;
            if (body.code === 0) {
                this.isUseWalletPay = body.data;
            }
        })
    }
}
