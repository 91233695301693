import {BaseRequest} from "@/request/BaseRequest";

class StoredControl extends BaseRequest {

    public requestPath: string = '';

    /**
     * 允许使用余额支付
     */
    getUseWalletPay(): any {
        return this.get('/marketing/storedControl/controlGet')
    }

    setUseWalletPay(): any {
        return this.post('/marketing/storedControl/controlSet')
    }

}

const c = new StoredControl();
export {c as StoredControl};